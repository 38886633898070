import Link from "next/link";
import { useParams } from "next/navigation";
import React from "react";

import { TNextClientComponent } from "types/next";

import { useTranslation } from "hooks/useTranslation";

import Container from "components/tailwind-ui/Container";
import Icon from "components/tailwind-ui/Icon";

import { styles } from ".";

const ErrorLayout: TNextClientComponent<{}> = () => {
  const params = useParams();
  const { t } = useTranslation(params?.lang as string);

  return (
    <div className={styles.container}>
      <Container type="center">
        <div className={styles.content}>
          <div className={styles.head}>
            <h1 className={styles.title}>Oops.</h1>
            <p className={styles.description}>
              {t("layout.error.description")}
            </p>
          </div>
          <Link href="/">
            <div className={styles.button.container}>
              <Icon type="sharp-light" accessor="arrow-right-long" />
              <p className={styles.button.label}>{t("layout.error.button")}</p>
            </div>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ErrorLayout;
