import React, { FC, ReactNode } from "react";

import { toClassName } from "helpers";

import { styles } from ".";

export type TProps = {
  children: ReactNode;

  type?: "break" | "center" | "fill";
};

const Container: FC<TProps> = (props) => {
  const { children, type = "break" } = props;

  const handleGenerateComponent = () => {
    let result;

    switch (type) {
      case "break":
        result = <div className={styles.base.container}>{children}</div>;
        break;
      case "center":
        result = (
          <div
            className={toClassName(
              styles.base.container,
              styles.center.container,
            )}
          >
            <div
              className={toClassName(
                styles.base.content,
                styles.center.content,
              )}
            >
              {children}
            </div>
          </div>
        );
        break;
      case "fill":
        result = <div className={styles.base.container}>{children}</div>;
        break;
    }

    return result;
  };

  return handleGenerateComponent();
};

export default Container;
